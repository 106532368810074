<template>
    <div
        v-if="!filterLoading"
        class="feedbacks-page business-feedbacks-page card card-padding h-100"
        :class="{ 'is-show-filter': showFilter }"
    >
        <PageHeader
            @showFilter="showFilter = true"
            :showFilter="showFilter"
            :search-input-text="$t('Search feedback...')"
            @handleInput="handleSearch"
        >
            <template #filter-badges>
                <BusinessFilterBadges
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    @setFilterLength="filterLength = $event"
                />
            </template>
        </PageHeader>

        <div class="feedbacks-wrapper">
            <div
                v-if="showFilter"
                class="filter feedbacks-wrapper__filter">
                <p
                    @click="showFilter = false"
                    class="closeFilter">
                    <img width="20" src="/media/buying/icons/close.svg" alt="">
                </p>
                <FeedbackFilter
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    :filterLength="filterLength"
                    @handle-apply-filter="applyFilter"
                />
            </div>
            <div class="feedbacks-wrapper__list mt-6">
                <template
                    v-if="!loading && surveys.length"
                    v-for="item in surveys"
                    :key="item.id"
                >
                    <BusinessFeedbackItem
                        :feedback="item"
                        :isFilterOpen="showFilter"
                        @onReplyFeedback="showReplyFeedbackPopup"
                    />
                </template>

                <div
                    v-else-if="!loading && !surveys.length"
                    class="h-100 d-flex align-items-center justify-content-center"
                >
                    <RequestIdeaEmptyState
                        :title="$t('No Session Found')"
                        :text="filterLength
                            ? $t('There are no feedback sessions to be found. It might have happened due to the filters applied. Please check filters or')
                            : $t('There are no feedback sessions created yet. Please check back later or')
                        "
                        :icon-path="filterLength
                            ? '/media/buying/icons/no-session-found-filter.png'
                            : '/media/buying/icons/no-session-found.svg'
                        "
                        :show-button="currentUser.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)"
                        :button-text="$t('Create Feedback')"
                        @handleClickButton="$router.push('/business/feedback/feedback-session/create')"
                    />
                </div>

                <CustomLoader height="400px" v-else/>
            </div>
        </div>
        <div></div>

        <FeedbackReplyPopup
            v-if="feedbackDataContainer"
            :popupVisible="isFeedbackReplyPopupVisible"
            :feedbackDataContainer="feedbackDataContainer"
            :for-logged-in="true"
            @handleSaveOrSkip="handleSaveOrSkip"
            @onClose="handleCloseReplyFeedbackPopup"
        />
    </div>

    <CustomLoader
        v-if="feedbackReplyLoading"
        full-screen
    />
</template>
<script>
import BusinessFeedbackItem from "@/buying-teams/pages/business/feedbacks/BusinessFeedbackItem";
import store from "@/store";
import FeedbackFilter from "@/buying-teams/pages/business/feedbacks/components/FeedbackFilter";
import Loader from "@/buying-teams/shared-components/utils/Loader";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import { BusinessFeedbackSessionFilterModel } from "@/store/models/filter/BusinessFeedbackSessionFilterModel";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import BusinessFilterBadges from "@/buying-teams/shared-components/filter/BusinessFilterBadges";
import { setCurrentPageBreadcrumbs, setCurrentPageCounter } from "@/core/helpers/breadcrumb";
import FeedbackReplyPopup from "@/buying-teams/pages/business/feedbacks/feedback-reply/FeedbackReplyPopup";
import { ReplyFeedbackDataContainer } from "@/store/models/business/feedback/ReplyFeedbackDataContainer";
import ReplyActionTypeEnum from "@/store/enums/ReplyActionTypeEnum";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "BusinessFeedbacks",

    components: {
        RequestIdeaEmptyState,
        BusinessFeedbackItem,
        FeedbackReplyPopup,
        BusinessFilterBadges,
        PageHeader,
        FeedbackFilter,
        Loader,
        CustomLoader
    },

    data() {
        return {
            BusinessUserPermissionTypeEnum,
            filterLoading: true,
            loading: true,
            surveys: [],
            showFilter: false,
            searchTimeoutId: null,
            filterLength: 0,
            filterData: {
                page: 0,
                type: "survey",
                search: "",
                filter: {
                    bank_ids: [],
                    time_period: "*",
                    product_areas: ["*"],
                    countries: []
                }
            },
            isFeedbackReplyPopupVisible: false,
            feedbackDataContainer: null,
            feedbackReplyLoading: false,
            ReplyActionTypeEnum,
        };
    },
    computed: {
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        businessFeedbackSessionFilter() {
            return store.getters.getBusinessFiltersState.feedbackSession
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        currentUser() {
            return store.getters.currentUser;
        }
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.$t('Feedback Sessions'),
            subTitle: this.$t('Score or review your Banks\' performance')
        })
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.FEEDBACK_SESSION);
        this.filterLoading = false;

        if (this.$route.query.id && this.currentUser.can(BusinessUserPermissionTypeEnum.REPLY_FEEDBACK_SESSION)) {
            await this.showReplyFeedbackPopup(this.$route.query.id);
            if (this.feedbackDataContainer.isCompleted) {
                this.isFeedbackReplyPopupVisible = false;
            }
        }
        this.setFilters(this.businessFeedbackSessionFilter)
        await this.getSurveys(this.filterData);
    },
    methods: {
        async getSurveys(filter) {
            this.surveys.length = 0;
            this.loading = true;
            this.surveys = await store.dispatch("getFeedbacksList", filter)
                .finally(() => {
                    this.loading = false;
                });

            setCurrentPageCounter({counter: this.surveys.length})
        },
        applyFilter(filters) {
            this.setFilters(new BusinessFeedbackSessionFilterModel(filters));
            this.getSurveys(this.filterData);
        },
        setFilters(filters) {
            if (filters) {
                this.filterData.filter = filters;
            }
        },
        async handleSearch(event) {
            let search = event.target.value

            if (search !== this.filterData.search && this.searchTimeoutId) clearTimeout(this.searchTimeoutId)
            this.filterData.search = search;

            this.searchTimeoutId = setTimeout(async () => {
                await this.getSurveys(this.filterData);
            }, 500);
        },
        async showReplyFeedbackPopup(feedbackId) {
            if (feedbackId) {
                this.$router.replace({ name: "business-feedbacks", query: { id: feedbackId } });

                this.feedbackReplyLoading = true;
                await store.dispatch("getSurveyById", feedbackId)
                    .then(res => {
                        this.feedbackDataContainer = new ReplyFeedbackDataContainer(res);
                        this.isFeedbackReplyPopupVisible = true;
                    })
                    .catch(err => {
                        console.error("feedback data fetch failed", err);
                    })
                    .finally(() => {
                        this.feedbackReplyLoading = false;
                    });
            }
        },
        handleCloseReplyFeedbackPopup() {
            this.feedbackDataContainer = null;
            this.isFeedbackReplyPopupVisible = false;
            this.$router.replace({ name: "business-feedbacks" });
            this.getSurveys(this.filterData);
        },
        handleSaveOrSkip({action, data}) {
            this.feedbackDataContainer.setSubmittingLoadingStatus(true);
            store.dispatch(action === ReplyActionTypeEnum.REPLY ? 'replyToSurvey' : 'skipQuestion', data)
                .then(async (res) => {
                    if (res) {
                        if (res.is_survey_completed) {
                            await store.dispatch('getSurveysStatsAuth', this.feedbackDataContainer.id)
                                .then((res) => {
                                    this.feedbackDataContainer.setCompletedStats(res);
                                })
                            this.feedbackDataContainer.setCompleted();
                        } else {
                            this.feedbackDataContainer.nextQuestion();
                        }
                    }
                })
                .catch(err => {
                    console.error('handleSaveOrSkip ', err);
                })
                .finally(() => {
                    this.feedbackDataContainer.setSubmittingLoadingStatus(false);
                })
        }
  }
};
</script>
<style lang="scss">
.business-feedbacks-page {
    @media (max-width: 768px) {
        .page-header {
            flex-wrap: nowrap !important;
            padding-top: 20px;

            > .w-100 {
                width: max-content !important;
                margin-top: 0 !important;
            }
        }
    }
}
</style>
