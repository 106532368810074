<template>
  <div class="demo-filterForm">
    <el-form :model="filterForm" ref="businessFeedbackFilterForm" :rules="businessFeedbackFilterFormRules">
      <el-form-item class="me-6" prop="time_period">
        <label class="d-block" for="">{{ $t("Time Period") }}</label>
        <el-select
            v-model="filterForm.time_period"
            :placeholder="$t('Time Period')"
        >
          <el-option
              v-for="(item,index) in timePeriods"
              :key="index"
              :label="item.title"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="product_areas">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>

          <label class="d-block" for="">{{ $t("Product Area") }}</label>
          <el-select
            v-model="filterForm.product_areas"
            multiple
            collapse-tags
            disabled
            :placeholder="$t('Select Product Area')">

            <el-option
              v-for="(item, index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="bank_ids">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogBanksVisible = true" class="select-popup"></div>

          <label class="d-block">{{ $t("Banks") }}</label>
          <el-select
            v-model="filterForm.bank_ids"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Banks')"
          >
            <el-option
                v-for="(item,index) in banks"
                :key="index"
                :label="item.name"
                :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6 mb-20" prop="countries">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>

          <label class="d-block mt-5" for="">{{ $t("Country of the Bank") }}</label>
          <el-select
            v-model="filterForm.countries"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Countries')">
            <el-option
              v-for="(item, index) in countries"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <!--    <el-form-item>-->
      <!--      <div class="d-flex justify-content-between mt-5">-->
      <!--        <label for="" class="d-block">{{ $t("Sort by who created the request") }}</label>-->
      <!--        <p class="text-primary" @click="dialogCreatorsVisible = true">{{ $t("View All") }}</p>-->
      <!--        <div v-if="dialogCreatorsVisible">-->
      <!--          <SelectModal-->
      <!--            :popupVisible="dialogCreatorsVisible"-->
      <!--            :dataForSelect="creators"-->
      <!--            multiple="true"-->
      <!--            :title="$t('Select Creators')"-->
      <!--            :subtitle="$t('Select Creators ')"-->
      <!--            :placeholder="$t('Search Creators')"-->
      <!--            @handle-close="dialogCreatorsVisible = false"-->
      <!--            @handle-confirm="handleConfirm"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <el-checkbox-group v-model="filterForm.creators" class="d-flex">-->
      <!--       <div-->
      <!--         v-for="(creator, i) in creators"-->
      <!--         :key="i"-->
      <!--       >-->
      <!--         <el-checkbox-button-->
      <!--           v-if="i < 5"-->
      <!--           :label="creator.name"-->
      <!--           name="creators"-->
      <!--           class="custom-check">-->
      <!--           <div class="circle-image"></div>-->
      <!--           <p class="mt-3 mb-2 fw-bold">{{ creator.name }}</p>-->
      <!--           <p class="text-gray">{{ creator.position }}</p>-->
      <!--         </el-checkbox-button>-->
      <!--       </div>-->
      <!--      </el-checkbox-group>-->
      <!--    </el-form-item>-->


      <!--    <el-form-item class="switch" :label="$t('Show closed requests')">-->
      <!--      <el-switch v-model="filterForm.closed"></el-switch>-->
      <!--    </el-form-item>-->

    </el-form>

    <div class="filters-counts mb-6 me-6" v-show="filterLength">
      <span>
        <div class="filter-badges">{{ filterLength }}</div>
        {{ $t('Filters Selected') }}
      </span>
      <button @click="clearAll">{{ $t('Clear All') }}</button>
    </div>

    <button class="main-btn-outline filter-btn" @click="submitForm">{{ $t("Apply Filters") }}</button>
  </div>

  <SelectModal
    v-if="dialogBanksVisible"
    :popupVisible="dialogBanksVisible"
    :dataForSelect="banks"
    :dataForUpdate="filterForm.bank_ids"
    :multiple="true"
    :title="$t('Select Banks')"
    :subtitle="$t('Select relevant banks')"
    :placeholder="$t('Search Banks')"
    @handle-close="dialogBanksVisible = false"
    @handle-confirm="handleConfirm"
  />

  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="filterForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />

  <SelectModal
    v-if="dialogCountriesVisible"
    :title="$t('Select Country')"
    :subtitle="$t('Select Country you want to filter on')"
    :placeholder="$t('Search Country')"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="countries"
    :dataForUpdate="filterForm.countries"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogCountriesVisible = false"
    @handle-confirm="handleConfirmCountries"
  />
</template>

<script>
  import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";

  export default {
    name: "FeedbackFilter",
    components: {
      SelectModal
    },

    props: {
      banks: Array,
      countries: Array,
      productAreas: Array,
      timePeriods: Array,
      initialFilters: Object,
      filterLength: Number
    },

    emits: [
      "handle-apply-filter"
    ],

    created() {
      this.fillFilter(this.initialFilters);
    },

    data() {
      return {
        dialogBanksVisible: false,
        dialogCreatorsVisible: false,
        dialogProductAreaVisible: false,
        dialogCountriesVisible: false,
        filterForm: {
          time_period: "",
          product_areas: [],
          countries: [],
          creators: [],
          bank_ids: []
          // closed: false
        },
        businessFeedbackFilterFormRules: {
          time_period: [
            { required: true, message: this.$t("Please select Time Period"), trigger: "change" },
          ],
          bank_ids: [
            { required: true, message: this.$t("Please select Bank"), trigger: "change" }
          ],
          product_areas: [
            { required: true, message: this.$t("Please select Product Areas"), trigger: "change" }
          ],
          countries: [
            { required: true, message: this.$t("Please select Countries"), trigger: "change" }
          ]
        }
      };
    },

    methods: {
      handleConfirm(event) {
        this.filterForm.bank_ids = event;
        this.dialogFormVisible = false;
      },
      handleConfirmProductAreas(event) {
        this.filterForm.product_areas = event;
        this.dialogProductAreaVisible = false;
      },
      handleConfirmCountries(event) {
        this.filterForm.countries = event;
        this.dialogCountriesVisible = false;
      },
      submitForm() {
        this.$refs.businessFeedbackFilterForm.validate(valid => {
          if (valid) {
            this.$emit("handle-apply-filter", this.filterForm);
          }
        })
      },
      fillFilter(filterData) {
        this.filterForm.bank_ids = filterData.bank_ids;
        this.filterForm.countries = filterData.countries;
        this.filterForm.product_areas = filterData.product_areas;
        this.filterForm.time_period = filterData.time_period;
      },
      clearAll() {
        this.filterForm.bank_ids = this.banks.map(b => b.id);
        this.filterForm.countries = this.countries;
        this.filterForm.product_areas = this.productAreas;
        this.filterForm.time_period = '*';
        this.submitForm();
      }
    }
  };
</script>

<style lang="scss">
</style>
