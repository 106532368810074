<template>
    <div
        class="feedback-item"
        :class="{
            'is-filter-open': isFilterOpen,
            ['feedback-item__' + feedback.status.statusClass]: true
        }"
    >
        <div class="feedback-item__header">
            <div class="feedback-item__header--left">
                <div class="header-bar">{{ feedback.phases.join(", ") }}</div>
                <div class="header-bar">
                    {{ $t("Start Date") }}
                    <div v-html="dateFormatInTimeZone(feedback.session_start)" class="date-time-zone"></div>
                </div>
                <div class="header-bar">
                    {{ $t("End Date") }}
                    <div v-html="dateFormatInTimeZone(feedback.session_end)" class="date-time-zone"></div>
                </div>
            </div>
            <div class="feedback-item__header--right">
                <!--        <div class="invitees-section">-->
                <!--          {{ $t('Invitees') }} <span>29</span>-->
                <!--        </div>-->

                <StatusComponent
                    :text="feedback.status.statusName"
                    :type="feedback.status.statusClass"
                />
            </div>
        </div>

        <div class="feedback-item__body">
            <div class="card-info">
                <div class="card-info__header" v-if="feedback.creator">
                    <span>{{ $t("Created by") }}</span>
                    <div class="card-info__header--user" v-if="feedback.is_bank_survey && feedbackBankData">
                        <img :src="feedbackBankData.icon_path" alt="">
                        <span>{{ feedbackBankData.name }}</span>
                    </div>
                    <div class="card-info__header--user" v-else>
                        <img :src="feedback.creator.icon_path" alt="">
                        <span>{{ feedback.creator.full_name }}</span>
                    </div>
                </div>
                <div class="card-info__body">
                    <router-link :to="`/business/feedback/feedback-session/${feedback.id}/result`"
                                 class="card-info__body--title">
                        {{ feedback.title }}
                    </router-link>
                    <div class="card-info__body--description">
                        {{ feedback.description }}
                    </div>
                </div>
            </div>
            <div class="bank-info">
                <div class="bank-info__single-item" v-if="feedback.is_bank_survey && feedbackBankData">
                    <img :src="feedbackBankData.icon_path" alt="">
                    <h4>{{ feedbackBankData.name }}</h4>
                    <p>{{ feedbackBankData.countries.join(", ") }}</p>
                </div>
                <div class="bank-info__item" v-else v-for="(item, index) in feedback.banks_data" :key="index">
                    <div class="bank-name">
                        <img :src="item.icon_path" alt="">
                        <span>{{ item.name }}</span>
                    </div>
                    <div class="bank-countries">
                        {{ item.countries.slice(0, 3).join(", ") }}<span
                        v-if="item.countries.length > 3">, +{{ item.countries.length - 3 }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="feedback-item__footer">
            <div class="footer-info">
                <div class="footer-info__item">
                    {{ $t("Completed") }} <span>{{ feedback.answered_questions }} {{ $t("of")
                    }} {{ feedback.total_questions }}</span>
                </div>
                <div class="footer-product-area" v-if="feedback.product_areas">
                    <span>{{ $t("Product Areas") }}</span>
                    <div class="footer-product-area__item" v-for="area in feedback.product_areas.slice(0, 3)">{{ area
                        }}
                    </div>
                    <el-dropdown v-if="feedback.product_areas.length > 3">
                        <span class="footer-product-area__item cursor-pointer">
                          +{{ feedback.product_areas.length - 3 }}
                        </span>
                        <template #dropdown>
                            <template v-for="(area, index) in feedback.product_areas">
                                <div v-if="index > 2" class="footer-product-area__dropdown">{{ area }}</div>
                            </template>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="footer-actions">
                <button
                    class="footer-actions__reply"
                    v-if="(feedback.status.statusName === SurveyStatuses.LIVE ||
                        feedback.status.statusName === SurveyStatuses.UPCOMING ||
                        feedback.status.statusName === SurveyStatuses.EXPIRING_SOON) &&
                        currentUser.can(BusinessUserPermissionTypeEnum.REPLY_FEEDBACK_SESSION)"
                    @click="handleReplyFeedback(feedback.id)"
                    :disabled="feedback.status.statusName === SurveyStatuses.UPCOMING"
                >
                    <img src="/media/buying/icons/buttonArrow.svg" width="9" alt="">
                    <span>{{ $t("Reply now") }}</span>
                </button>
                <button class="footer-actions__view" @click="handleViewSession" v-else>
                    {{ $t("View Session") }}
                    <img src="/media/buying/icons/arrow-left-black.svg" width="10" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import { SurveyStatuses } from "@/store/models/SurveyModel";
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "BusinessFeedbackItem",
    components: { StatusComponent },
    props: {
        feedback: Object,
        isFilterOpen: Boolean
    },
    emits: ["onReplyFeedback"],
    data() {
        return {
            dateFormatInTimeZone,
            SurveyStatuses,
            BusinessUserPermissionTypeEnum
        };
    },
    computed: {
        feedbackBankData() {
            if (!this.feedback.banks_data.length) return null;
            return this.feedback.banks_data[0];
        },
        currentUser() {
            return store.getters.currentUser;
        }
    },
    methods: {
        handleReplyFeedback(feedbackId) {
            this.$emit("onReplyFeedback", feedbackId);
        },
        handleViewSession() {
            this.$router.push(`/business/feedback/feedback-session/${this.feedback.id}/result`);
        }
    }
};
</script>

<style lang="scss" scoped>
.feedback-item {
    border-right: 6px solid transparent;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    margin-bottom: 8px;
    overflow: hidden;

    &__live {
        border-color: #435BF4;
    }

    &__success {
        border-color: #28A57F;
    }

    &__secondary {
        border-color: #B9B9B9;
    }

    &__danger {
        border-color: #EA3333;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 10px;
        background: #FAFAFA;

        &--left {
            display: flex;
            align-items: center;
            width: 100%;
            overflow-x: auto;
            padding-bottom: 5px;
            margin-bottom: -5px;

            > * {
                white-space: nowrap;
            }

            .header-bar {
                padding: 0 10px;
                font-weight: 400;
                font-size: 11px;
                line-height: 158.02%;
                color: #969696;

                &:not(:last-child) {
                    border-right: 1px solid #969696;
                }

                &:nth-child(1) {
                    color: #030303;
                }
            }
        }

        &--right {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: 10px;

            > * {
                white-space: nowrap;
            }

            .invitees-section {
                font-weight: 400;
                font-size: 11px;
                line-height: 158.02%;
                color: #969696;

                span {
                    font-size: 16px;
                    color: #000000;
                    font-weight: 700;
                }
            }
        }
    }

    &__body {
        display: flex;
        align-items: stretch;

        .card-info {
            margin-left: 15px;
            border-right: 1px solid #F2F2F2;
            border-bottom: 1px solid #F2F2F2;
            @media (min-width: 1440px) {
                width: 30%;
            }

            &__header {
                border-bottom: 1px solid #F2F2F2;
                padding: 10px 0;
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                > span {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 158.02%;
                    color: #969696;
                    margin-right: 22px;
                }

                &--user {
                    img {
                        width: 26px;
                        height: 26px;
                        object-fit: cover;
                        margin-right: 8px;
                        border-radius: 50%;
                        border: 1px solid #f0f0f0;
                    }

                    span {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #2B2B2B;
                    }
                }
            }

            &__body {
                padding-bottom: 14px;

                &--title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 158.02%;
                    color: #2B2B2B;
                    margin-bottom: 8px;
                    display: inline-block;
                    transition: .3s ease-in-out;

                    &:hover {
                        color: #435BF4;
                    }
                }

                &--description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 158.02%;
                    color: #595959;
                }
            }
        }

        .bank-info {
            padding-left: 20px;
            margin-right: 20px;
            padding-top: 4px;
            padding-bottom: 4px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            border-bottom: 1px solid #F2F2F2;
            @media (min-width: 1440px) {
                width: 70%;
            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #FAFAFA;
                border-radius: 12px;
                padding: 10px;

                .bank-name {
                    display: flex;
                    align-items: center;

                    img {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 8px;
                        border: 1px solid #ccc;
                    }

                    span {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #2B2B2B;
                    }
                }

                .bank-countries {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #595959;
                }
            }

            &__single-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 20px 0;

                img {
                    width: 52px;
                    height: 52px;
                    object-fit: cover;
                    border-radius: 50%;
                    object-position: center;
                    margin-bottom: 20px;
                    border: 1px solid #f0f0f0;
                }

                h4 {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #2B2B2B;
                    margin-bottom: 10px;
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #595959;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__footer {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-info {
            display: flex;
            align-items: center;
            width: calc(100% - 140px);
            overflow-x: auto;
            padding-bottom: 5px;
            margin-bottom: -5px;

            &__item {
                font-weight: 300;
                font-size: 12px;
                line-height: 13px;
                color: #696969;
                padding-right: 26px;
                border-right: 1px solid #0000000d;
                width: max-content;
                white-space: nowrap;

                span {
                    color: #000000;
                    font-weight: 700;
                }
            }

            .footer-product-area {
                padding-left: 26px;
                display: flex;
                align-items: center;
                gap: 6px;

                > span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #969696;
                    white-space: nowrap;
                }

                &__item {
                    background: #F8F8F8;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    padding: 8px 7px;
                    white-space: nowrap;
                }
            }
        }

        .footer-actions {
            button {
                border-radius: 300px;
                transition: .3s ease-in-out;
                border: none;
                padding: 10px 16px;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                gap: 8px;
            }

            &__reply {
                background: #F5F7FE;
                color: #435BF4;

                &:not(:disabled):hover {
                    background: #e2e9ff;
                }

                &:disabled {
                    background: transparent;
                    opacity: 0.3;
                }
            }

            &__view {
                background: transparent;
                color: #435BF4;

                img {
                    filter: invert(32%) sepia(91%) saturate(2950%) hue-rotate(225deg) brightness(96%) contrast(100%);
                }

                &:hover {
                    background: #F5F7FE;
                }
            }
        }
    }

    // FILTER OPEN
    &.is-filter-open {
        .feedback-item__body {
            .card-info {
                width: 300px;
            }

            .bank-info {
                width: calc(100% - 335px);
            }
        }

        @media (min-width: 1200px) and (max-width: 1600px) {
            .feedback-item__body {
                .card-info {
                    width: 211px;
                }

                .bank-info {
                    width: calc(100% - 246px);
                }

                .bank-info__item {
                    flex-direction: column;
                    align-items: flex-start;

                    .bank-name {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        @media (min-width: 1200px) and (max-width: 1439px) {
            .feedback-item__body {
                flex-direction: column;

                .card-info {
                    width: 100%;
                    margin-left: 0;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-right: 0;
                }

                .bank-info {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 1439px) {
        &__body {
            .card-info {
                width: 300px;
            }

            .bank-info {
                width: calc(100% - 335px);
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        &__body {
            .bank-info__item {
                flex-direction: column;
                align-items: flex-start;

                .bank-name {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        &__body {
            flex-direction: column;

            .card-info {
                width: 100%;
                margin-left: 0;
                border-right: 0;

                &__header, &__body {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            .bank-info {
                width: 100%;
                margin-right: 0;
                padding: 15px;
            }
        }
        &__footer {
            flex-direction: column;
            align-items: flex-end;
            position: relative;

            .footer-info {
                width: 100%;
                overflow: revert;

                &__item {
                    position: absolute;
                    bottom: 20px;
                    padding-right: 0;
                    border-right: 0;
                }

                .footer-product-area {
                    padding-left: 0;
                    overflow-x: auto;
                    padding-bottom: 5px;
                    margin-bottom: -5px;
                }
            }

            .footer-actions {
                margin-top: 15px;
            }
        }
    }

    @media (max-width: 600px) {
        &__header {
            flex-direction: column-reverse;
            align-items: flex-end;

            &--left {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 450px) {
        &__body {
            .bank-info {
                overflow-x: auto;
                flex-direction: row;
                padding-bottom: 4px;

                &__item {
                    width: max-content;

                    .bank-name {
                        span {
                            white-space: nowrap;
                        }
                    }

                    .bank-countries {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.footer-product-area__dropdown {
    padding: 5px 10px;
}
</style>
