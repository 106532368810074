<template>
    <div class="filter-badges" v-if="filterLength">{{ filterLength }}</div>
</template>

<script>
export default {
    name: "BusinessFilterBadges",
    props: {
        banks: Array,
        countries: Array,
        productAreas: Array,
        timePeriods: Array,
        initialFilters: Object,
        priorities: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            filterLength: 0
        }
    },
    watch: {
        initialFilters: {
            deep: true,
            handler() {
                this.setFilterLength();
            }
        }
    },
    mounted() {
        this.setFilterLength();
    },
    methods: {
        setFilterLength() {
            let banks = this.initialFilters.bank_ids.length === this.banks.length || !this.initialFilters.bank_ids.length ? 0 : 1;
            let countries = this.initialFilters.countries.length === this.countries.length || !this.initialFilters.countries.length ? 0 : 1;
            let product_areas = this.initialFilters.product_areas.length === this.productAreas.length || !this.initialFilters.product_areas.length ? 0 : 1;
            let time_period = this.initialFilters.time_period === '*' || !this.initialFilters.time_period ? 0 : 1;
            let priorities = this.initialFilters.priority
                ? (this.initialFilters.priority.length === this.priorities.length || !this.initialFilters.priority.length ? 0 : 1)
                : 0;

            this.filterLength = banks + countries + product_areas + time_period + priorities;
            this.$emit('setFilterLength', this.filterLength);
        }
    }
}
</script>

<style scoped lang="scss">
.filter-badges {
    width: 21px;
    height: 21px;
    position: absolute;
    background: #E22D21;
    border: 1px solid #FFFFFF;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -10px;
    left: -17px;
}
</style>
